import React from 'react'
import { Link } from 'gatsby'
import instagram from '../img/social/instagram.svg'
import linkedin from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        Formación
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/faq">
                        Preguntas Frecuentes
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contacto
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/info">
                        Datos Útiles
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/talleres">
                        Talleres y Capacitaciones
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a
                  title="instagram"
                  href="https://instagram.com/sexualidadeslibres"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a
                  title="linkedin"
                  href="https://www.linkedin.com/in/b%C3%A1rbara-garc%C3%ADa-634182163/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={linkedin}
                    alt="LinkedIn"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
