import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import picktimeLogo from "../img/picktime-book-online-right.png";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.png" />

        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"
        ></link>

        <meta name="facebook-domain-verification" content="dztjpb1ezqrtfcm3bssfc262up2w81" />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <a
        href="https://www.picktime.com/DraBarbaraGarcia"
        target="_blank"
        style={{
          position: "fixed",
          right: "0px",
          top: "50%",
          marginTop: "-90px",
          float: "none",
          zIndex: "9999999",
        }}
        rel="noopener noreferrer"
      >
        <img
          border="none"
          src={picktimeLogo}
          alt="Reserva un turno con Dra. B&aacute;rbara Garc&iacute;a"
        />
      </a>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
